import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Button from '../Button'
import DrawSvg from "../DrawSvg";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-top: 40px;

`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;
const Container = styled.div`
  width: 70%;
  height: 250vh;
  background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  /* background-color: lightblue; */

  @media (max-width: 48em) {
    padding-top: 0;
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
      text-align: left;
        p {
          border-radius: 0 40px 0 40px;

        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }
    div {
      border-radius: 0 50px 0 50px;
      text-align: left;

      
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;
const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;

  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  color: ${(props) => props.theme.text};

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const RoadMapItem = ({ title, subtext, addToRef, children }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <SubTitle>{title} </SubTitle>
          <Text>{subtext}</Text>
          {children}
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",

          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
            // markers:true,
          },
        }
      );
    });

    return () => {
      if (t1) t1.kill();
    };
  }, []);

  return (
    <Section id="roadmap">
      <Title>Features</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadMapItem
            addToRef={addToRefs}
            title="Collect"
            subtext="We have a collection of 3333 ultra-high-quality 3D NFTs on the Elrond Blockchain. Raptors come with 11 layers, filled with wacky and awesome traits including the elusive gold skin and much more!"
          >
            <Button text="Get some" link="https://deadrare.io/collection/RAPTOR-28e21c"/>
          </RoadMapItem>
          <RoadMapItem
            addToRef={addToRefs}
            title="Fusions"
            subtext="An industry-leading feature that allows for the customisation of your NFTs. Fusions allow you to combine the rarest traits from your favourite Raptors. "
          >
            <Button text="WOWZA" link="https://docs.rogueraptors.io/information/fusions"/>
          </RoadMapItem>
          <RoadMapItem
            addToRef={addToRefs}
            title="Staking"
            subtext="Survival of the fittest. The strongest raptors in the land are rewarded the most. Raptors with more ‘power’ earn more $RAPT."
          >
            <Button text="Earn me some RAPT" link="https://docs.rogueraptors.io/information/raptor-staking"/>
          </RoadMapItem>
          <RoadMapItem
            addToRef={addToRefs}
            title="CandySwap"
            subtext="Buy and Sell your $RAPT token on CandySwap!"
          >
            <Button text="Swap now" link="https://candyswap.gg/token/RAPT-98e3c6"/>
          </RoadMapItem>
          <RoadMapItem
            addToRef={addToRefs}
            title="EGLD Farming"
            subtext="Farm your RAPT to earn EGLD generated from trading royalties and fusions."
          >
            <Button text="Tell me more" link="https://docs.rogueraptors.io/information/faqs"/>
          </RoadMapItem>
          <RoadMapItem
            addToRef={addToRefs}
            title="Retro Raptors"
            subtext="Transform your Raptors into pixel form! Coming soon."
          >
            <Button text="RRRRRR" link="https://docs.rogueraptors.io/information/retro-raptors-coming-soon"/>
          </RoadMapItem>
          <RoadMapItem
            addToRef={addToRefs}
            title="Eggs"
            subtext="The eggs have ben laid, waiting to hatch...."
          >
            <Button text="Stock up" link="https://deadrare.io/collection/EGGS-df6177"/>
          </RoadMapItem>
        </Items>
      </Container>
    </Section>
  );
};

export default Roadmap;
